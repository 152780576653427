import React from 'react';
import styled from 'styled-components';

function ReasonForMissing({ reasonForMissing, setReasonForMissing, setOtherReasonText, reasons }) {
  if (reasonForMissing === 1) {
    return (
      <>
        <S.P>Reason for missing:</S.P>
        <S.SelectReason
          value={reasonForMissing}
          onChange={e => {
            setReasonForMissing(Number(e.target.value));
            setOtherReasonText(undefined);
          }}
        >
          <option value={0}>--Choose a reason--</option>
          {reasons}
        </S.SelectReason>
        <S.P>Other reason:</S.P>
        <S.OtherReasonText
          placeholder='Please provide "Other Reason" to continue.'
          onChange={e => setOtherReasonText(e.target.value)}
        />
      </>
    );
  } else {
    return (
      <>
        <S.P>Reason for missing:</S.P>
        <S.SelectReason value={reasonForMissing} onChange={e => setReasonForMissing(Number(e.target.value))}>
          <option value={0}>--Choose a reason--</option>
          {reasons}
        </S.SelectReason>
      </>
    );
  }
}

const S = {};

S.P = styled.p`
  font-weight: bold;
  margin: 0;
  text-align: center;
`;

S.SelectReason = styled.select`
  box-sizing: border-box;
  text-align-last: center;
  padding: 5px;
  margin: 7.5px 0;
  border: solid;
`;

S.OtherReasonText = styled.textarea`
  box-sizing: border-box;
  resize: none;
  padding: 5px;
  margin: 7.5px 0;
  border: solid;
`;

export default ReasonForMissing;
