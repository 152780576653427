import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import htmlParser from 'react-html-parser';

import Login from './components/login';
import Logo from './components/logo.png';
import ReasonForMissing from './components/reason-for-missing';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function App() {
  const [loading, setLoading] = useState();

  const [email, setEmail] = useState();
  const [pin, setPin] = useState();

  const [view, setView] = useState(0);

  const [error, setError] = useState();
  const [errorBody, setErrorBody] = useState({});

  const [noMeetings, setNoMeetings] = useState();

  const [missAttendInfo, setMissAttendInfo] = useState();

  const [homeFacilitator, setHomeFacilitator] = useState();

  const [upcoming, setUpcoming] = useState();

  const [upcomingIds, setUpcomingIds] = useState();
  const [upcomingGroupsInfo, setUpcomingGroupsInfo] = useState();
  const [missing, setMissing] = useState();

  const [substitutionList, setSubstitutionList] = useState();
  const [substitution, setSubstitution] = useState();

  const [listOfReasons, setListOfReasons] = useState();
  const [reasonForMissing, setReasonForMissing] = useState();
  const [otherReasonText, setOtherReasonText] = useState();

  const [scrollTop, setScrollTop] = useState(0);

  const [filter, setFilter] = useState();

  const [showMore, setShowMore] = useState();

  useEffect(() => {
    // Scroll to selected meeting in step2
    const mCont = document.getElementById('mCont');
    if (mCont && scrollTop) {
      mCont.scrollTop = scrollTop;
    }
  }, [view]);

  async function attemptLogin(captchaValue) {
    setLoading(true);
    const body = {
      email,
      pin,
      captchaValue
    };
    const rawResponse = await fetch('/login', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
    const response = await rawResponse.json();
    if (response.noMeetings) {
      setNoMeetings(true);
      setErrorBody({ message: response.message });
      setView(1);
      setLoading(false);
      return;
    }
    if (response.error) {
      setError(true);
      setErrorBody(response.body || { message: response.message });
      setLoading(false);
      return;
    }
    setMissAttendInfo(response.missAttendInfo);
    setHomeFacilitator(response.homeFacilitator);
    setUpcoming(response);
    setUpcomingIds(response.meetings.map(m => m.id));
    setUpcomingGroupsInfo(response.groups);
    setListOfReasons(response.reasonsForMissing);
    setError(false);
    setView(1);
    setLoading(false);
  }

  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }

  function formatInitialList(meetingsData) {
    const meetings = meetingsData.meetings.map(meeting => {
      const groupId = meeting[meetingsData.structure.groupId];
      const group = meetingsData.groups.find(g => g.id === groupId);
      const id = meeting.id;
      const date = meeting[meetingsData.structure.date];
      const formatedDate = formatDate(date);
      const day = days[meeting.dayIndex];
      const hour = group.meetingHour;
      const type = group.type;
      const facilitator = group.facilitator;
      const address = group.address;
      const timeZone = group.timeZone;
      const info = JSON.stringify({
        id,
        groupId,
        date,
        formatedDate,
        day,
        hour,
        type,
        address,
        facilitator,
        timeZone
      });
      return (
        <S.InList
          key={id}
          data-id={id}
          data-info={info}
          onClick={willMiss}
          selected={missing ? missing.id === id : false}
        >
          <S.Date>
            <S.Text>{day}</S.Text>
            <S.Text>{formatedDate}</S.Text>
            <S.Text>
              {hour} {timeZone}
            </S.Text>
          </S.Date>
          <S.TypeAuthor>
            <S.Text>{type}</S.Text>
            <S.Text>{facilitator}</S.Text>
          </S.TypeAuthor>
          {type === 'Local' ? <S.Address>{address}</S.Address> : ''}
        </S.InList>
      );
    });
    return meetings;
  }

  function willMiss(e) {
    const groupInfo = JSON.parse(e.currentTarget.dataset.info);
    if (missing && missing.id === groupInfo.id) {
      return;
    }
    setMissing(groupInfo);
  }

  function willAttend(e) {
    const groupInfo = JSON.parse(e.currentTarget.dataset.info);
    if (substitution && substitution.id === groupInfo.id) {
      return;
    }
    setSubstitution(groupInfo);
  }

  async function getSubstitutionList() {
    setLoading(true);

    const groupInfo = upcomingGroupsInfo.find(g => g.id === missing.groupId);

    const body = {
      email,
      pin,
      upcomingIds,
      missing,
      groupInfo,
      missAttendInfo
    };
    const rawResponse = await fetch('/substitutionList', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
    const response = await rawResponse.json();
    if (response.noMeetings) {
      setNoMeetings(true);
      setErrorBody({ message: response.message });
      setView(2);
      setLoading(false);
      return;
    }

    if (response.error) {
      setError(true);
      setErrorBody(response.body);
      setLoading(false);
      return;
    }

    setSubstitutionList(response);
    setView(2);
    setLoading(false);
  }

  function formatSubstList(meetingsData) {
    const sameFacilitator = [];
    const otherMeetings = [];
    meetingsData.meetings.forEach(m => {
      const dateFiledName = meetingsData.structure.date;
      const groupIdFieldName = meetingsData.structure.supportGroupId;

      const id = m.id;
      // Filter out meeting group out meeting groups array
      const group = meetingsData.groups.filter(g => g.id === m[groupIdFieldName])[0];
      const groupId = group.id;
      const date = m[dateFiledName];
      const formatedDate = formatDate(date);
      const day = days[m.dayIndex];
      const hour = group.meetingHour;
      const type = group.type;
      const facilitator = group.facilitator;
      const address = group.address;
      const timeZone = group.timeZone;
      const info = JSON.stringify({
        id,
        groupId,
        date,
        formatedDate,
        day,
        hour,
        type,
        address,
        facilitator,
        timeZone
      });
      const text = `${formatedDate} ${day} ${hour} ${type} ${address} ${facilitator} ${timeZone}`.toLowerCase();
      const meeting = (
        <S.InList
          key={id}
          id={id}
          data-info={info}
          onClick={willAttend}
          selected={substitution ? substitution.id === id : false}
          text={text}
        >
          <S.Date>
            <S.Text>{day}</S.Text>
            <S.Text>{formatedDate}</S.Text>
            <S.Text>
              {hour} {timeZone}
            </S.Text>
          </S.Date>
          <S.TypeAuthor>
            <S.Text>{type}</S.Text>
            <S.Text>{facilitator}</S.Text>
          </S.TypeAuthor>
          {type === 'Local' ? <S.Address>{address}</S.Address> : ''}
        </S.InList>
      );
      if (homeFacilitator === facilitator) {
        sameFacilitator.push(meeting);
      } else {
        otherMeetings.push(meeting);
      }
    });

    let meetings;
    if (sameFacilitator.length) {
      const sF = sameFacilitator.filter(containQuery);
      const oM = otherMeetings.filter(containQuery);

      meetings = (
        <React.Fragment>
          {sF}
          {oM.length ? (
            <React.Fragment>
              <S.ShowMore onClick={() => setShowMore(true)} show={!showMore}>
                Show more
              </S.ShowMore>
              {showMore ? oM : ''}
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    } else {
      meetings = otherMeetings.filter(containQuery);
    }
    return meetings;
  }

  function backToStep1() {
    setView(1);
    setScrollTop(0);
    setSubstitution(false);
    setShowMore(false);
    setError(false);
    setErrorBody({});
    setNoMeetings(false);
  }

  function backToStep2() {
    setView(2);
  }

  async function replaceMeeting() {
    setLoading(true);
    const body = {
      email,
      pin,
      missing: missing.id,
      substitution: substitution.id,
      reasonForMissing,
      otherReasonText
    };
    const rawResponse = await fetch('/replaceMeeting', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
    const response = await rawResponse.json();
    if (response.error) {
      setError(true);
      setErrorBody(response.body);
      setLoading(false);
      return;
    }
    setView(4);
    setLoading(false);
  }

  function setSearchStr(e) {
    let searchQ = e.currentTarget.value.split(' ');
    setFilter(searchQ);
  }

  function containQuery(meeting) {
    return filter ? filter.every(query => meeting.props.text.includes(query.toLowerCase())) : true;
  }

  function toStep3() {
    setView(3);
    let contScroll = document.getElementById('mCont').scrollTop;
    setScrollTop(contScroll);
  }

  const errorPopup = (
    <S.contErrorPopup onClick={() => setError(false)}>
      <S.ErrorPopup>
        <p>{errorBody.message}</p>
        <p>
          {errorBody.status ? `Status: ${errorBody.status}` : ''} {errorBody.code ? `Code: ${errorBody.code}` : ''}
        </p>
      </S.ErrorPopup>
    </S.contErrorPopup>
  );

  // Login
  if (view === 0) {
    return (
      <S.Wrapper>
        <S.contMain>
          {loading ? <S.Loading>Loading...</S.Loading> : ''}
          <Login
            attemptLogin={attemptLogin}
            setEmail={setEmail}
            setPin={setPin}
            error={error}
            errorMessage={errorBody.message}
          />
        </S.contMain>
      </S.Wrapper>
    );
  }

  // 1. Choose meeting to reschedule
  else if (view === 1) {
    return (
      <S.Wrapper>
        <S.contMain>
          {error ? errorPopup : ''}
          {loading ? <S.Loading>Loading...</S.Loading> : ''}
          <S.H3> 1. Choose meeting to reschedule</S.H3>
          {noMeetings ? (
            <S.NoMeetings>{htmlParser(errorBody.message)}</S.NoMeetings>
          ) : (
            <S.contList>{formatInitialList(upcoming)}</S.contList>
          )}
          <S.contButtons>
            <S.Next visible={missing} onClick={getSubstitutionList}>
              Next
            </S.Next>
          </S.contButtons>
        </S.contMain>
      </S.Wrapper>
    );
  }

  // 2. Choose the substitution meeting
  else if (view === 2) {
    return (
      <S.Wrapper>
        <S.contMain>
          {error ? errorPopup : ''}
          {loading ? <S.Loading>Loading...</S.Loading> : ''}
          <S.H3> 2. Choose the make up meeting</S.H3>
          <S.contList id="mCont">
            <S.P>You will miss:</S.P>
            <S.Missing>
              <S.Date>
                <S.Text>{missing.day}</S.Text>
                <S.Text>{missing.formatedDate}</S.Text>
                <S.Text>
                  {missing.hour} {missing.timeZone}
                </S.Text>
              </S.Date>
              <S.TypeAuthor>
                <S.Text>{missing.type}</S.Text>
                <S.Text>{missing.facilitator}</S.Text>
              </S.TypeAuthor>
              {missing.type === 'Local' ? <S.Address>{missing.address}</S.Address> : ''}
            </S.Missing>
            <S.P>Replace with:</S.P>
            {noMeetings ? (
              <S.NoMeetings>{htmlParser(errorBody.message)}</S.NoMeetings>
            ) : (
              <React.Fragment>
                <S.Search onChange={setSearchStr} placeholder="Search..." value={filter ? filter.join(' ') : ''} />
                {formatSubstList(substitutionList)}
              </React.Fragment>
            )}
          </S.contList>
          <S.contButtons>
            <S.Back onClick={backToStep1}>Back</S.Back>
            <S.Next visible={substitution} onClick={toStep3}>
              Next
            </S.Next>
          </S.contButtons>
        </S.contMain>
      </S.Wrapper>
    );
  }

  // 3. Confirm and submit your selection
  else if (view === 3) {
    const reasons = listOfReasons.map(r => {
      const id = r[0];
      const name = r[1];
      return (
        <option key={id} value={id}>
          {name}
        </option>
      );
    });
    return (
      <S.Wrapper>
        <S.contMain>
          {error ? errorPopup : ''}
          {loading ? <S.Loading>Loading...</S.Loading> : ''}
          <S.H3> 3. Confirm and submit your selection</S.H3>
          <S.contList2>
            <S.P>Missing:</S.P>
            <S.Missing>
              <S.Date>
                <S.Text>{missing.day}</S.Text>
                <S.Text>{missing.formatedDate}</S.Text>
                <S.Text>
                  {missing.hour} {missing.timeZone}
                </S.Text>
              </S.Date>
              <S.TypeAuthor>
                <S.Text>{missing.type}</S.Text>
                <S.Text>{missing.facilitator}</S.Text>
              </S.TypeAuthor>
              {missing.type === 'Local' ? <S.Address>{missing.address}</S.Address> : ''}
            </S.Missing>
            <S.P>Replace with:</S.P>
            <S.Selected>
              <S.Date>
                <S.Text>{substitution.day}</S.Text>
                <S.Text>{substitution.formatedDate}</S.Text>
                <S.Text>
                  {substitution.hour} {substitution.timeZone}
                </S.Text>
              </S.Date>
              <S.TypeAuthor>
                <S.Text>{substitution.type}</S.Text>
                <S.Text>{substitution.facilitator}</S.Text>
              </S.TypeAuthor>
              {substitution.type === 'Local' ? <S.Address>{substitution.address}</S.Address> : ''}
            </S.Selected>
            <ReasonForMissing
              reasonForMissing={reasonForMissing}
              setReasonForMissing={setReasonForMissing}
              setOtherReasonText={setOtherReasonText}
              reasons={reasons}
            />
          </S.contList2>
          <S.contButtons>
            <S.Back onClick={backToStep2}>Back</S.Back>
            <S.Next
              visible={reasonForMissing ? (reasonForMissing === 1 ? otherReasonText : true) : false}
              onClick={replaceMeeting}
            >
              Submit
            </S.Next>
          </S.contButtons>
        </S.contMain>
      </S.Wrapper>
    );
  }

  // 4. Success screen
  else if (view === 4) {
    return (
      <S.Wrapper>
        <S.contMain>
          {loading ? <S.Loading>Loading...</S.Loading> : ''}
          <S.Logo src={Logo} alt="logo" />
          <S.H3>You have successfully replaced your meeting.</S.H3>
          <S.contList2>
            <S.P>Missing:</S.P>
            <S.Missing>
              <S.Date>
                <S.Text>{missing.day}</S.Text>
                <S.Text>{missing.formatedDate}</S.Text>
                <S.Text>
                  {missing.hour} {missing.timeZone}
                </S.Text>
              </S.Date>
              <S.TypeAuthor>
                <S.Text>{missing.type}</S.Text>
                <S.Text>{missing.facilitator}</S.Text>
              </S.TypeAuthor>
              {missing.type === 'Local' ? <S.Address>{missing.address}</S.Address> : ''}
            </S.Missing>
            <S.P>Replaced with:</S.P>
            <S.Selected>
              <S.Date>
                <S.Text>{substitution.day}</S.Text>
                <S.Text>{substitution.formatedDate}</S.Text>
                <S.Text>
                  {substitution.hour} {substitution.timeZone}
                </S.Text>
              </S.Date>
              <S.TypeAuthor>
                <S.Text>{substitution.type}</S.Text>
                <S.Text>{substitution.facilitator}</S.Text>
              </S.TypeAuthor>
              {substitution.type === 'Local' ? <S.Address>{substitution.address}</S.Address> : ''}
            </S.Selected>
          </S.contList2>
          <S.contButtons>
            <S.Button onClick={() => window.location.reload()}>Replace another meeting?</S.Button>
          </S.contButtons>
        </S.contMain>
      </S.Wrapper>
    );
  }
  return <h1>Nothing</h1>;
}

const S = {};

S.Loading = styled.div`
  position: absolute;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(128, 128, 128, 0.8);
`;

S.Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.contMain = styled.div`
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

S.H3 = styled.h3`
  display: flex;
  text-align: center;
  align-items: center;
  margin: 15px 5px;
`;

S.contList = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: stretch;
  padding: 7.5px 15px;
`;

S.contList2 = styled(S.contList)`
  justify-content: center;
`;

// Error pop-up

S.contErrorPopup = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(128, 128, 128, 0.95);
`;

S.ErrorPopup = styled.div`
  max-width: 600px;
  padding: 15px;
  margin: auto;
  border: solid red 10px;
  background-color: white;
`;

// Meetings

S.Meeting = styled.div`
  cursor: default;
  margin: 7.5px 0;
  border: solid;
  border-radius: 5px;
`;

S.InList = styled(S.Meeting)`
  cursor: pointer;
  background-color: ${props => (props.selected ? 'skyblue' : '')};
`;

S.Missing = styled(S.Meeting)`
  border-color: red;
`;

S.Selected = styled(S.Meeting)`
  border-radius: 5px;
`;

S.Search = styled.input`
  padding: 5px;
`;

S.Date = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

S.Text = styled.div`
  box-sizing: border-box;
  padding: 5px;
  white-space: nowrap;
`;

S.TypeAuthor = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid lightgrey;
`;

S.Address = styled.div`
  padding: 5px;
  border-top: solid lightgrey;
`;

// Buttons

S.contButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  margin-top: auto;
`;

S.Button = styled.button`
  font-size: 1.2rem;
  min-width: 125px;
  min-height: min-content;
  border: solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
`;

S.Back = styled(S.Button)`
  color: white;
  background-color: red;
`;

S.Next = styled(S.Button)`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  color: white;
  background-color: green;
`;

S.ShowMore = styled(S.Button)`
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: #e9e292;
`;
// Misc

S.P = styled.p`
  font-weight: bold;
  margin: 0;
  text-align: center;
`;

S.Logo = styled.img`
  box-sizing: border-box;
  margin: 10px;
  max-width: 300px;
`;

S.NoMeetings = styled.div`
  box-sizing: border-box;
  min-width: 300px;
  overflow: auto;
  font-weight: bold;
  text-align: center;
  margin: 15px;
  padding: 5px;
`;

export default App;
