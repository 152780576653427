import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import htmlParser from 'react-html-parser';

import Logo from './logo.png';

function Login(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showTooltip, setShowTooltip] = useState();

  useEffect(() => {
    setError(props.error);
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage, props.error]);

  async function attemptLogin(e) {
    e.preventDefault();
    let captchaValue = document.getElementById('g-recaptcha-response').value;

    if (!captchaValue) {
      setError(true);
      setErrorMessage('You need to click on "I\'m not a robot" checkbox, before submitting');
      return;
    }
    props.attemptLogin(captchaValue);
  }

  function toggleTooltip() {
    setShowTooltip(!showTooltip);
  }

  const tooltip = (
    <S.contTooltip onClick={toggleTooltip}>
      <S.Tooltip>
        Your Pin / ID is the unique number given to you by your program. You may use this number for your daily testing
        checkin.
      </S.Tooltip>
    </S.contTooltip>
  );

  return (
    <React.Fragment>
      <S.Logo src={Logo} alt="logo" />
      <S.Form id="form">
        {showTooltip ? tooltip : ''}
        <S.Fieldset>
          <legend>
            <h1>Login</h1>
          </legend>
          <S.Label htmlFor="email">Email</S.Label>
          <S.Input
            onChange={e => props.setEmail(e.target.value.toLowerCase())}
            id="email"
            name="email"
            type="email"
            placeholder={'Enter your email'}
          />
          <S.Label htmlFor="pin">
            PIN / ID <S.TooltipIcon onClick={toggleTooltip}>?</S.TooltipIcon>
          </S.Label>
          <div></div>
          <S.Input
            onChange={e => props.setPin(e.target.value)}
            id="pin"
            name="pin"
            type="password"
            placeholder={'Enter your PIN'}
          />
        </S.Fieldset>
        <S.Captcha className="g-recaptcha" data-sitekey="6Lf3GcQUAAAAAJl-bYW19qjoFsLyQPMoO3tVFO8t"></S.Captcha>
        {error ? <S.ErrorMessage>{htmlParser(errorMessage)}</S.ErrorMessage> : ''}
        <S.Submit type="submit" onClick={attemptLogin}>
          Submit
        </S.Submit>
      </S.Form>
    </React.Fragment>
  );
}

const S = {};

S.Logo = styled.img`
  box-sizing: border-box;
  margin: 10px;
  max-width: 300px;
  @media (max-height: 600px) {
    width: 50%;
  }
`;

S.Form = styled.form`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

S.Fieldset = styled.fieldset`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-radius: 5px;
`;

S.Label = styled.label`
  display: block;
  margin: 5px 0px;
`;

S.TooltipIcon = styled.div`
  display: Inline-flex;
  justify-content: center;
  font-weight: bold;
  color: deepskyblue;
  width: 21.6px;
  border: solid;
  border-radius: 100%;
`;

S.contTooltip = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
S.Tooltip = styled.p`
  max-width: 600px;
  padding: 15px;
  margin: 10px;
  border: solid;
  border-radius: 10px;
  background-color: white;
`;

S.Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

S.Captcha = styled.div`
  box-sizing: border-box;
  margin: 10px;
`;

S.Submit = styled.button`
  font-size: 1.3rem;
  font-weight: bold;
  min-height: min-content;
  padding: 10px 30px;
  border: solid 1px;
  border-radius: 5px;
  padding: 5px 10px;
  :hover {
    background: grey;
  }
`;

S.ErrorMessage = styled.div`
  box-sizing: border-box;
  min-width: 300px;
  overflow: auto;
  font-weight: bold;
  text-align: center;
  margin: 15px;
  padding: 5px;
  border: solid red;
`;

export default Login;
